import(/* webpackMode: "eager", webpackExports: ["RB2BElement","PHProvider"] */ "/vercel/path0/typescript/fiddle-frontend/app/_components/PosthogProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/typescript/fiddle-frontend/app/_components/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/fiddle-frontend/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/fiddle-frontend/app/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/typescript/fiddle-frontend/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@14.2.14_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@14.2.14_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/node_modules/.pnpm/next@14.2.14_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/playground-common/src/baml_wasm_web/JotaiProvider.tsx");
